<template>
  <div class="login">
    <div class="login-content">
      <div class="logo-view">
        <img v-if="!lange" class="logo-img" src="../static/img/logo-cn.png" />
        <img v-if="lange" class="logo-img2" src="../static/img/logo-eng.png" />
        <div class="logo-title" style="color: #fff">{{ $t("loginPage.title") }}</div>
      </div>
      <div class="flex-row login-right">
        <el-button v-if="false" class="btn-black">{{ $t("loginPage.experience") }} Demo</el-button>
        <div class="login-text">
          <span class="hover" @click="langeSwitch">
            {{
            lange ? "ENG" : "中"
            }}
          </span>
        </div>
      </div>
      <div class="login-view">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          style="padding-top: 0; width: 360px"
        >
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="loginForm.username"
              type="text"
              ref="loginForm.username"
              :placeholder="$t('loginPage.userNamePlaceHolder')"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              v-model="loginForm.password"
              type="password"
              ref="loginForm.password"
              :placeholder="$t('registerPage.passwordPlaceholder')"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="codeBox">
              <el-input
                prefix-icon="el-icon-picture-outline-round"
                v-model="loginForm.code"
                type="text"
                ref="loginForm.code"
                :placeholder="$t('loginPage.graphicalCodePlaceHolder')"
                clearable
              ></el-input>
              <el-image class="codeImg" :src="code" fit="contain" @click="getCode"></el-image>
            </div>
          </el-form-item>
          <el-form-item class="short-margin">
            <el-button
              class="btn-black"
              :loading="loading"
              type="primary"
              @click="login"
            >{{ $t("loginPage.loginBtn") }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              class="btn-green"
              type="primary"
              @click="$router.push('/register')"
            >{{ $t("loginPage.regBtn") }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="version">{{ $t("loginPage.version") }} {{ version }}</div>
    </div>
    <div class="login-bg"></div>
  </div>
</template>
<script>
import rules from '../plugins/rules';
import Web3 from 'web3';
import { connect, resolveRoleId, getUser } from '../utils/blockchain';
import { Loading } from 'element-ui';
import Storage from '../utils/storage';
import commonList from '../routers/common-list';
import store from "@/plugins/store";
export default {
  data() {
    return {
      version: localStorage.getItem("version") || "1.0.0",
      loginForm: {
        username: '',
        password: '',
        code: ''
      },
      code: '',
      //  效验规则
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: rules.validateAccount }
        ],
        password: [
          { required: true, trigger: 'blur', validator: rules.validatePass }
        ],
        code: [
          { required: true, trigger: 'blur', validator: rules.validateGraphical }
        ]
      },
      loading: false,
      lange: false
    };
  },
  created() {
    this.getCode();
  },
  mounted() {
    this.lange = Storage.getLocalItem('i18n') === 'zh-en';
    let local = this.lange ? 'zh-en' : 'zh-cn';
    this.$i18n.locale = local;
    Storage.setLocalItem('i18n', local);
  },
  methods: {
    // 获取图形验证码
    getCode() {
      this.$axios({ method: 'get', url: `/v1/captcha/svg`, responseType: 'blob' }).then(result => {
        this.code = window.URL.createObjectURL(result);
      });
    },
    login() {
      this.loading = true;
      const loginrUrl = '/v1/login/user';
      this.$axios.post(loginrUrl, this.loginForm).then(result => {
        if (result.code === 0 || result.code === '0') {
          this.loading = false;
          const loginData = { ...result.data };
          loginData.type = loginData.role === 1 ? 'LOAN' : loginData.role === 2 ? 'INVEST' : 'CORE';
          this.$store.dispatch('auth/loginSuccess', loginData);
          let routerLink = "/";
          const statusPage = loginData.approved;
          switch (loginData.role) {
            case 1:
              routerLink = statusPage === 0 ? '/company-create' : statusPage === 1 ? '/review' : statusPage === 2 ? 'supply-all' : statusPage === 3 ? '/reject' : '/invitation-code';
              break;
            case 2:
              routerLink = statusPage === 0 ? '/invest-create' : statusPage === 1 ? '/review' : statusPage === 2 ? 'invest-all' : '/reject';
              break;
            case 3:
              routerLink = statusPage === 0 ? '/enterprise-create' : statusPage === 1 ? '/review' : statusPage === 2 ? 'enterprise-all' : '/reject';
              break;
          }
          this.$router.replace({ path: routerLink });
        } else {
          this.loadingCode = false;
          this.loading = false;
          this.loginForm.code = '';
          this.getCode();
          this.$message.error(result.message);
        }
        // const type = this.role === '2' ? 'INVEST' : this.role === '1' ? 'LOAN' : 'CORE';
        // this.$store.dispatch('auth/loginSuccess', {
        //   username: this.loginForm.username || this.loginForm.company,
        //   id: result.id,
        //   authorization: this.address || '',
        //   type: type || 'INVEST'
        // });
        // localStorage.setItem('type', type); // 登陆角色记录
        // // 根据返回的状态以及当前选中的角色，判断跳转路由 UNKNOWN:未认证;PROCESSING:认证中;CONFIRM:已认证;REJECT:认证拒绝;
        // const respStatus = {
        //   UNKNOWN: "UNKNOWN",
        //   PROCESSING: "PROCESSING",
        //   CONFIRM: "CONFIRM",
        //   REJECT: "REJECT"
        // };
        // Storage.setLocalItem("isConfirm", result.auth === respStatus.CONFIRM);
        // let existCodeUrl = result.existCode ? "/company-create" : "/invitation-code";
        // let pageFlag = result.auth === respStatus.UNKNOWN ? 0 : result.auth === respStatus.PROCESSING ? 1 : result.auth === respStatus.CONFIRM ? 9 : 99;
        // let routerLink = "/";
        // switch (this.role) {
        //   case '2':
        //     routerLink = pageFlag === 0 ? "/invest-create" : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/invest-all" : "/reject";
        //     break;
        //   case '1':
        //     routerLink = pageFlag === 0 ? existCodeUrl : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/supply-all" : "/reject";
        //     break;
        //   case '3':
        //     routerLink = pageFlag === 0 ? "/enterprise-create" : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/enterprise-all" : "/reject";
        //     break;
        // }

        // const commonPath = commonList.find(item => {
        //   this.loading = false;
        //   return item.path === routerLink;
        // });
        // this.$store.dispatch('auth/isSide', !commonPath);

        // this.loading = false;
        // this.$router.replace({ path: routerLink });
      }).catch(err => {
        this.loading = false;
        console.log("login error {}", err);
      });
    },
    // 语言切换
    langeSwitch() {
      this.lange = !this.lange;
      let local = this.lange ? 'zh-en' : 'zh-cn';
      Storage.setLocalItem('i18n', local);
      this.$i18n.locale = local;
      this.$store.dispatch('auth/language', local);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
$light_gray: #fff;
$cursor: #fff;
.switch {
  position: absolute;
  right: 10px;
  top: 10px;
}
.login {
  min-width: 1000px;
  height: 100vh;
  background: linear-gradient(180deg, #d8f1ef 0%, #80d3cb 50%, #80d3cb 100%);
  .el-input {
    display: inline-block;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      caret-color: $cursor;
    }
  }
}
.login-content {
  position: relative;
  min-width: 1000px;
  max-width: 1600px;
  height: 100vh;
  margin: 0 auto;
  z-index: 9;
  .logo-view {
    position: absolute;
    bottom: 25%;
    left: 70px;
    font-size: 40px;
    font-family: "fontfmb";
    font-weight: 500;
    letter-spacing: 4px;
    .logo-title {
      padding-left: 15px;
    }
    .logo-img {
      width: 430px;
      height: 150px;
      margin-left: 0px;
    }
    .logo-img2 {
      width: 676px;
      height: 164px;
      margin-left: 0px;
    }
  }
  .login-tv {
    display: inline-block;
    width: 280px;
  }
  .logo-img {
    width: 480px;
    height: 370px;
    margin: 0 70px 0 30px;
  }
  .login-right {
    position: absolute;
    right: 80px;
    top: 80px;
    .login-text {
      font-size: 18px;
      font-weight: bold;
      margin-left: 26px;
      width: 40px;
      .hover {
        cursor: pointer;
      }
    }
  }
  .login-view {
    position: absolute;
    right: 80px;
    top: 120px;
    // width: 376px;
    height: auto;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0 18px 0;
    .radio-view {
      width: 308px;
      padding: 11px 10px;
      border: 1px solid #80d3cb;
      background: #80d3cb;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .el-radio {
        margin-right: 10px !important;
      }
    }
    .short-margin {
      margin-bottom: 10px;
    }
    .btn-black,
    .btn-green {
      width: 100%;
    }
  }
}
.el-form {
  position: relative;
  max-width: 450px;
  padding: 30px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}
.codeBox {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .codeImg {
    min-width: 93px;
    border: 1px solid #000000;
    border-left: none;
  }
}
.version {
  position: absolute;
  bottom: 78px;
  right: 68px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.login-bg {
  width: 100%;
  min-width: 1000px;
  min-height: 80%;
  background: url("../static/img/logo-bg.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
}
::v-deep .is-checked .el-radio__inner {
  border-color: #000000 !important;
  background: #000000 !important;
}
::v-deep .is-checked .el-radio__label {
  color: #000000 !important;
}
::v-deep .el-input__inner {
  border-color: #000000 !important;
}
</style>
